import { useEffect, useState } from "react";

const useObserver = (props) => {
	const { outerRef, threshold, rootMargin, animateOnce, animateBackwards } =
		props;

	const [inViewport, setInViewport] = useState(false);

	useEffect(() => {
		let observerReset;

		// Observes the target taking into account the threshold
		// and sets the state to true when it is in the viewport
		const onChange = (entries) => {
			entries.forEach((entry) => {
				if (entry.target === outerRef.current && entry.isIntersecting) {
					setInViewport(true);
				} else if (
					animateBackwards &&
					entry.target === outerRef.current &&
					entry.boundingClientRect.y > 0
				) {
					setInViewport(false);
				}
			});
		};
		const observer = new IntersectionObserver(onChange, {
			threshold,
			rootMargin,
		});
		outerRef.current && observer.observe(outerRef.current);

		// Observes the target regardless the threshold
		// and sets the state to false when it is under the bottom of the viewport
		if (!animateOnce) {
			const onChangeReset = (entries) => {
				entries.forEach((entry) => {
					if (
						entry.target === outerRef.current &&
						entry.boundingClientRect.y > window.innerHeight
					) {
						setInViewport(false);
					}
				});
			};
			observerReset = new IntersectionObserver(onChangeReset);
			outerRef.current && observerReset.observe(outerRef.current);
		}

		return () => {
			observer?.disconnect();
			observerReset?.disconnect();
		};
	}, [outerRef]);

	return inViewport;
};

export { useObserver };
