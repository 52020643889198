export default {
	languageNames: {
		en: "Inglês",
		es: "Espanhol",
		pt_PT: "Português",
		ca: "Catalão",
	},
	hello: "Olá",
	ui: {
		breadcrumb: {
			home: "Início",
		},
		pagination: {
			error: "Lamentamos, mas não há resultados para a sua pesquisa.",
			previous: "Anterior",
			next: "Seguinte",
			loading: "A carregar...",
		},
		eventInfo: {
			when: "Quando",
			where: "Onde",
			hour: "Hora",
			eventType: "Tipo de evento",
		},
	},
	form: {
		header: {
			request: "Pedido de informações",
			lightBoxRequest:
				"Preencha o formulário com os seus dados para solicitar informações",
			requestLong:
				"Preenche o formulário com os teus dados para solicitar informações",
			register: "Inscreve-te",
			req: "Pedido de Informações",
			reqSub: "Envia-nos a tua informação e entraremos em contacto brevemente",
			subtitle:
				"Deixe-nos os seus dados e entraremos em contacto consigo brevemente",
			text: "PENDING TO TRANSLATION",
		},
		confirmation: {
			error: {
				title:
					"Houve um erro no envio do formulário, lamentando o inconveniente.",
				subtitle: "O número é inválido, tente novamente.",
				detail: "Por favor, tente novamente.",
				errorData: "",
			},
			success: {
				title: "Obrigado!",
				subtitle: "Iremos entrar em contacto contigo em breve.",
				admissionDetail: "",
				admissionSubtitle: "",
				admissionAttachDetail: "",
				admissionAttachSubtitle: "",
				okTitle: "",
				ok: "",
			},
		},
		fields: {
			label: {
				name: "Nome",
				lastName: "Apelido",
				email: "Email",
				contactEmail: "Email",
				phone: "Telemóvel",
				courseType: "Tipo de curso",
				courseDefault: "Curso de interesse",
				academicStatus: "Forma de ingresso",
				country: "Nacionalidade",
				companyCountry: "",
				companyName: "",
				schoolName: "Nome da Escola/Colégio",
				institution: "Instituição onde concluiu",
				documentNumber: "Número do documento",
				courseProperties: {
					location: "Localização",
					courseMode: "Regime",
					knowledgeArea: "Área",
					course: "Curso de interesse",
					programType: "Tipo de curso",
				},
				teamName: "Nome Equipa",
				participants: "Número de participantes",
				participantName: "Nome participante",
				participantLastName: "Apelido participante",
				participantEmail: "E-mail participante",
				participantPhone: "Telemóvel participante",
				participantCountry: "País participante",
				participantSchool: "Escola secundária participante",
				participantSchoolYear: "Ano escolar participante",
				academicDocuments: "Documentos acadêmicos",
				academicTranscript: "Histórico escolar (máx. 2 MB)",
				selectFile: "Selecionar arquivo",
				professionalDocuments: "Documentos profissionais",
				curriculumVitae: "Curriculum vitae (máx. 2 MB)",
				coverLetter: "Carta de apresentação (máx. 2 MB)",
				send: "Enviar",
				department: "",
				jobTitle: "",
				labelWantToGoWithInformationRequest:
					"Não estão disponíveis Bolsas de Estudos.",
				wantToGoWithInformationRequest:
					"Pretende avançar com o pedido de informação?",
				hasCompletedSecondaryEducation: "Ensino Secundário Concluído?",
				yearCompletedSecondaryEducation: "Conclusão Ensino Secundário",
				yearCompletedSecondaryEducationSuperior:
					"Data de conclusão do ensino superior",
				indicateYourAccessExam: "Indique exames de acesso",
				takingAnyOfTheseExams: "Indique futuros exames de acesso",
				accessExamsOptions: {
					abiturGermany: {
						label: "Abitur - Alemanhã",
						value: "Abitur - Alemanhã",
					},
					europeanBAC: {
						label: "BAC Europeu",
						value: "BAC Europeu",
					},
					cambridgeAssessmentInternationalEducation: {
						label: "Cambridge Assessment Interna- tional Education",
						value: "Cambridge Assessment Interna- tional Education",
					},
					enem: {
						label: "Enem",
						value: "Enem",
					},
					internationalStudentStatus: {
						label: "Estatuto do Estudante Internacional",
						value: "Estatuto do Estudante Internacional",
					},
					USAAPExams: {
						label: "EUA — AP Exams",
						value: "EUA — AP Exams",
					},
					USASATExams: {
						label: "EUA — SAT Exams",
						value: "EUA — SAT Exams",
					},
					portugueseNationalExams: {
						label: "Exames Nacionais Portugueses",
						value: "Exames Nacionais Portugueses",
					},
					internationalBaccalaureateDiploma: {
						label: "International Baccalaureate Di- ploma (IB Diploma)",
						value: "International Baccalaureate Di- ploma (IB Diploma)",
					},
					unitedKingdomGCE: {
						label: "Reino Unido — GCE",
						value: "Reino Unido — GCE",
					},
					others: {
						label: "Outros",
						value: "Outros",
					},
				},
				wantStartClasses: "Início das aulas",
				yesOption: "Sim",
				noOption: "Não",
				regime: "Regime*",
				haveBachelorDegree: "Tem curso superior?",
			},
			error: {
				generic: "Este campo é obrigatório",
				phone: "Por favor introduza um número válido",
				email: "Por favor introduza um endereço de correio electrónico válido",
				name: "Por favor introduza o seu nome",
				lastName: "Por favor introduza o seu apelido",
				datePickerStart:
					"Para submeter este formulário tens de ter idade igual ou superior a ",
				datePickerFinish: " anos",
				birthday: "A data de nascimento é obrigatória",
				yearFinishSecondarySchool:
					"O ano de conclusão do Ensino Médio é obrigatório",
				number: "Por favor introduza um número válido",
			},
			tmg: {
				participant: "Participante",
				buttonNext: "Seguinte",
				buttonPrevious: "Anterior",
			},
		},
		legalLink: "política de privacidade",
		legalText:
			"Autorizo que os dados aqui recolhidos sejam utilizados, de acordo com a ",
		legalText2:
			" da ENSILIS, para ser contactado para saber mais sobre os produtos, serviços e eventos da universidade, via email, apps de messaging, telefone ou SMS. Ao clicar e prosseguir declaro ter 16 anos ou mais.",
		legalText3:
			'Os dados facultados neste formulário serão tratados pelo responsável pelo tratamento, CENTRO EUROPEO DE ESTUDIOS Y FORMACIÓN EMPRESARIAL GARRIGUES, S.L. (doravante, CEG), com a finalidade de gerir esta aplicação, a gestão de diversas actividades para as quais faculte os seus dados, assim como o envio de publicidade e actividades do CEG que possam ser do seu interesse por correio, telefone ou meios electrónicos (correio eletrónico, SMS, mensagens e outros meios de comunicação eletrónica). O fundamento para o tratamento dos dados pessoais fornecidos no âmbito desta aplicação é o desenvolvimento e execução da relação formalizada com o titular dos dados, bem como o cumprimento das obrigações legais da CEG e o consentimento inequívoco do titular dos dados. Os dados facultados em virtude do presente pedido serão incluídos num ficheiro automatizado do qual é responsável a CEG, com domicílio para estes efeitos na Avenida de Fernando Alonso nº 8, 28108 Alcobendas (Madrid). De igual modo, salvo indicação expressa em contrário, o titular consente expressamente o tratamento automatizado, total ou parcial, dos referidos dados durante o tempo necessário para o cumprimento das finalidades anteriormente mencionadas. O titular dos dados tem o direito de aceder, retificar e eliminar os dados, limitar o seu tratamento, opor-se ao seu tratamento e exercer o seu direito à portabilidade dos dados pessoais, tudo gratuitamente, conforme detalhado na informação completa sobre proteção de dados, no link https://www.centrogarrigues.com/politica-privacidad. Pode revogar o seu consentimento para receber comunicações comerciais ou promocionais a qualquer momento, contactando o responsável pelo tratamento de dados na Avenida de Fernando Alonso nº 8, 28108 Alcobendas (Madrid), ou enviando uma mensagem de correio eletrónico para o endereço dpo@centrogarrigues.com, indicando na linha de assunto a referência "revogação de publicidade".',
		button: "Enviar",
		inscribirme: "Inscrever-se",
		checkboxText: "Quero receber informações sobre o programa",
		yes: "Sim",
		no: "Não",
		yearSelect: "Ano escolar",
		birthdate: "Data de nascimento",
		schoolYearValue1: "12º ano/3º ano",
		schoolYearValue2: "11º ano/2º ano",
		schoolYearValue3: "10º ano/1º ano",
		schoolYearValue4: "Outros",
		easyPackDate: "Data prevista de chegada a Portugal",

		tipodedocumento: {
			label: "",
			types: {
				NIF: "",
				Pasaporte: "",
				TjtaResd: "",
				NIE: "",
				CIF: "",
				DocExt: "",
			},
		},
		chooseAnAnswer: "",
		sendFiles: "",
		attachmentsFormIntro: "",
		consent: "",
		attachPhoto: "",
		personalDocuments: "",
		personalDocDefaultName: "",
		complaint: {
			error: "Selecionar um tipo de consulta",
			label: "Tipo de pedido de informação",
			enquiryOptions: {
				complaint: "Queixa",
				suggestion: "Sugestão",
				claim: "Reclamação",
			},
		},
		imageUseConsent: `Autorizo que a ENSILIS proceda à utilização, reprodução e divulgação do meu
      nome, imagem e/ou voz (obtidos através de fotografias, vídeos ou gravações)
      de eventos nos quais eu intervenha, em ambiente físico ou em plataformas
      digitais, para organização e divulgação de eventos, apresentações
      institucionais, conferências, feiras e stands.`,
		year_finish_secondary_school: "Conclusão Ensino Médio",
		date_generic_error: "A data é obrigatória",
	},
	button: {
		viewMore: "Ver detalhe",
		readMore: "Ler mais",
		subscribe: "Inscrever-se",
		register: "Inscrições",
		filters: "Filtros",
		seeResults: "Ver resultados",
	},
	academicStatus: {
		st01: "Ensino secundário + exames nacionais",
		st02: "Titular de Curso Superior",
		st03: "Titular de Curso de Especialização Tecnológica (CET)",
		st04: "Disciplinas isoladas",
		st05: "Estatuto estudante internacional",
		st07: "Maiores de 23 anos",
		st08: "Mudança de instituição/curso",
		st09: "Reingresso",
		st13: "Titular de Cursos Técnicos Superiores Profissionais (CTeSP)",
	},
	filters: {
		filterBy: "Filtrar por",
		filters: "Filtros",
		clearFilters: "Eliminar filtros",
		seeResults: "Ver resultados",
		study: {
			programType: "Tipo de programa",
			courseMode: "Regime",
			location: "Localização",
			language: "Idioma",
			knowledgeArea: "Área de conhecimento",
			promotionYear: "Ano de promoção",
			program: "Programa",
		},
		totalResults: "resultados",
		search: "Pesquise por nome do estudo",
	},
	jobOffers: {
		seeOffer: "Veja oferta",
		location: "Localização",
		languages: "Línguas",
		degree: "Titulação",
		available: "ofertas disponíveis",
		filteredSingular: "Mostrando $1 oferta de $2 disponíveis",
		filteredPlural: "Mostrando $1 ofertas de $2 disponíveis",
	},
	blog: {
		by: "por",
		author: "Autor",
		category: "Categoria",
		tag: "Tag",
		allCategories: "Todas as categorias",
		available: "$1 artigos",
		filteredSingular: "Mostrando $1 artigo de $2",
		filteredPlural: "Mostrando $1 artigos de $2",
		articlesFromThisAuthor: "Artigos deste autor",
		seeAllFromAuthor: "Ver todos os artigos deste autor",
		publishedAt: "Publicado originalmente em",
		otherArticles: "Outros artigos",
		article: "Artigo",
		backToPostList: "Voltar à lista de publicações",
	},
	studyList: {
		available: "$1 resultados",
		filteredSingular: "Mostrando $1 resultado de $2",
		filteredPlural: "Mostrando $1 resultados de $2",
		location: "Localização",
		languages: "Idiomas",
		knowledgeArea: "Área",
		programType: "Tipo de programa",
	},
	btnViewMore: {
		error: "Lamentamos, mas não há resultados para a sua pesquisa.",
		progress: "Já viu $1 de $2 programas",
		more: "Ver mais",
		loading: "A carregar...",
	},
	moreInfo: "Mais informações",
	quickFacts: {
		location: "Localização",
		courseMode: "Regime",
		language: "Língua",
		start: "Início",
		dedication: "Duração",
		credits: "ECTS",
		learningOption: "Opção de aprendizagem",
	},
	selector_regimen: {
		onsite: "Presencial",
		online: "Online",
	},
	formCommon: {
		titulacionesRealizadas: "",
		esteCampoEsObligatorio: "",
		continuar: "",
		direcciNPostal: "",
		seleccionePais: "",
		pais: "",
		seleccioneRegion: "",
		campoRegionEsObligatorio: "",
		localidad: "",
		introduceLocalidad: "",
		codigoPostal: "",
		introduceCodigoPostal: "",
		domicilio: "",
		introduceDomicilio: "",
		cMoHasConocidoElCentroD: "",
		datosPersonalesYDeContacto: "",
		nombre: "",
		introduceElNombre: "",
		apellidos: "",
		introduceLosApellidos: "",
		telefonoFijo: "",
		opcional: "",
		introduceUnNumeroValido: "",
		datosProfesionales: "",
		consiente: "",
		consienteCheckboxText1: "",
		consienteCheckboxText2: "",
		level: "",
	},
	formAdmision: {
		espana: "",

		fotocopiaEscaneadaDeDniOD: "",
		expedienteAcadMico: "",
		currCulumVitae: "",
		cartaDeMotivaciNEnElCas: "",
		alCumplimentarEstaSolicitud: "",
		paraRealizarLaSolicitudCor: "",
		idiomasOpcional: "",
		volver: "",
		seleccionaElTipoDePrograma: "",
		estSInteresadoEnNuestroP: "",
		podrSOptarANuestroProgram: "",
		si: "",
		no: "",
	},
	formAward: {
		estudiosRealizados: "",
		centroDeEstudios: "",
		notaMedia: "",
		anoDeInicio: "",
		porFavorIntroduzcaAnoDeInicio: "",
		anoDeFin: "",
		porFavorIntroduzcaAnoDeFin: "",
		hemosRecibidoCorrectamente: "",
		muchasGraciasPorInscribirte: ``,
		yaEstasRegistrado: "",
		hemosDetectadoQueYaTeHasInscritoAnteriormente: "",
		enviar: "",
		indicarRespuesta: "",
	},
	formInfo: {
		title: "Contacto connosco",
		requestInfoText:
			"Para solicitar informações sobre os nossos estudos, pode fazê-lo através do formulário Pedido de informações. Se desejar iniciar o processo de admissão, pode fazê-lo através do nosso formulário de pedido de admissão. Também pode contactar-nos através do número +34 91 514 53 30. De qualquer forma, muito obrigado por nos contactar.",
		solicitar: "Candidatar-se à admissão",
		ok: "Em todo o caso, muito obrigado por nos ter contactado.",
		labelSubject: "Assunto",
		errorSubject: "Introduzir o assunto da mensagem",
		labelEmail: "Email",
		errorEmail: "Introduzir um endereço de e-mail válido",
		labelMessage: "Mensagem",
		errorMessage: "Introduzir a mensagem",
		sendBtn: "Enviar",
	},
	eventForm: {
		typeOfAssistance: "Escolha um tipo de assistência",
		labelEmail: "Email",
		errorEmail: "Escolha o programa que lhe interessa",
		selectProgram: "Escolha o programa que lhe interessa",
		sendBtn: "Enviar",
		jpa: {
			academicSituation: {
				title: "Situação académica",
				options: {
					eso: { value: "", label: "PENDING TRANSLATION" },
					bachelor1: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					bachelor2: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					over25: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					graduate: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					fp1: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					fp2: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					sameStudies: {
						value: "",
						label: "PENDING TRANSLATION",
					},
					differentStudies: {
						value: "",
						label: "PENDING TRANSLATION",
					},
				},
			},
			companions: "Acompanhantes",
			parentsAttend: {
				title: "Os pais assistem",
				options: {
					yes: "Sim",
					no: "Não",
				},
			},
		},
	},
	dates: {
		years: {
			singular: "ano",
			plural: "anos",
		},
		months: {
			singular: "mês",
			plural: "meses",
		},
		weeks: {
			singular: "semana",
			plural: "semanas",
		},
		days: {
			singular: "dia",
			plural: "dias",
		},
	},
	download: "download",
	pricing: "preçário",
	precario: "preçário",
	soon: "Em breve",
	invalidDate: "Data inválida",
	alumni: {
		viewProfile: "Ver perfil",
		deleteImage: "Eliminar",
		replaceImage: "Alterar",
		uploadPicture: "Carregar imagem",
		cancel: "Cancelar",
		save: "Guardar alterações",
		editProfile: "Editar perfil",
		goToAlumniList: "Ir para a lista de antigos alunos",
		deleteAccount: "Eliminar conta",
		searchAlumni: "Pesquisa de antigos alunos",
		backToSite: "Voltar ao Centro de Estudios Garrigues",
		completeProfile: "Completar o meu perfil",
		hi: "Olá ",
		label: {
			qualification: "Qualificação",
			biography: "Biografia",
			personalData: "Dados pessoais",
			professionalData: "Dados profissionais",
			contactData: "Dados de contacto",
			dataProcessing: "Processamento de dados",
		},
		form: {
			fields: {
				label: {
					name: "Nome",
					city: "Cidade",
					linkedln: "Linkedln",
				},
				error: {
					name: "O campo nome é obrigatório",
					city: "O campo cidade é obrigatório",
					linkedln: "O campo linkedln é obrigatório",
					dataProcessing: "Processamento de dados é obrigatório",
				},
			},
			altDefaultImage: "Carregar imagem",
			LightboxTitle: "Escolha a sua fotografia de perfil",
			LightboxSubTitle:
				"Formatos válidos: jpeg, jpg, png. Tamanho máximo: 2MB.",
			lightboxChangesWithoutSaving: {
				title: "Você tem algumas alterações não salvas",
				subtitle:
					"Se você sair, elas serão perdidas. Tem certeza de que deseja descartá-las?",
				discardChanges: "Descartar alterações",
				continue: "Continuar editando",
			},
		},
	},
	orIfYouPrefer: "Ou se preferir",
	searchResults: {
		title: "O que procura?",
		titleWithoutResults: "O que procura?",
		titleWithResults: "Todos os resultados",
		resultsFound: 'Encontrámos {number} resultado para "{search}"',
		studies: "Estudos",
		information: "Informação",
		placeholder: "Pesquisar",
		waitingSearchTitle: "Comece a sua pesquisa inserindo um termo no campo",
		waitingSearchSubtitle: "Os resultados da sua pesquisa aparecerão aqui",
		noStudiesFound:
			"Não encontramos resultados para a sua pesquisa. Tente modificar a sua pesquisa ou navegue no menu principal.",
		viewMore: "Ver mais",
	},
	searchField: {
		tryWith: "Tente com",
		degree: "Licenciatura",
		postgraduate: "Pós-graduação",
		master: "Mestrado",
		viewAllResults: "Ver todos os resultados",
	},
	search: "Pesquisar",
};
