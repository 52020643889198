import thumbnail1x from "@axthumbs/thumbnails/templates/SearchResults/thumbnail@1x.png";
import thumbnail2x from "@axthumbs/thumbnails/templates/SearchResults/thumbnail@2x.png";

export default {
	schemaType: "template",
	displayName: "Search Results",
	component: "SearchResults",
	dataPacks: null,
	type: { label: "Static", value: "static" },
	singleInstance: true,
	content: [],
	default: {
		type: "template",
		templateType: "SearchResults",
	},
	thumbnails: {
		"1x": thumbnail1x,
		"2x": thumbnail2x,
	},
};
