import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Job Offer Filters",
	component: "JobOfferFilters",
	dataPacks: ["JOB_OFFERS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Filters",
					type: "ReferenceField",
					sources: [
						{ structuredData: "STUDY" },
						{ structuredData: "LOCATION" },
						{ structuredData: "LANGUAGE" },
					],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "JobOfferFilters",
		title: "Job Offer Filters",
		data: {
			mode: "auto",
			sources: [
				{ structuredData: "STUDY" },
				{ structuredData: "LOCATION" },
				{ structuredData: "LANGUAGE" },
			],
			order: "alpha",
		},
		hasDistributorData: true,
	},
	thumbnails: {},
});
