import { useEffect, useState } from "react";

const useInViewportOrBelow = (ref) => {
	const [isInView, setInView] = useState(true);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const [entry] = entries;
				const { top, height } = entry.boundingClientRect;
				setInView(height + top > 0);
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0,
			}
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	return isInView;
};

export { useInViewportOrBelow };
