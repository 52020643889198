export default {
	SEARCH_RESULTS: {
		title: "Search Results",
		dataPacks: [],
		local: true,
		taxonomy: false,
		fromPage: true,
		translate: true,
		schema: {
			templates: ["SearchResults"],
			fields: [],
		},
		clone: null,
		defaultValues: null,
	},
};
